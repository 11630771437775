<template>
  <div>
    <b-table
      v-if="transactions.length"
      ref="refBillingDNTable"
      class="position-relative event_list shadow-table"
      :items="transactions"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(amount)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.amount) }}</span>
        </div>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No payment data found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    transactions: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'TrxDetail_Id',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'trxDetailID',
          label: 'TRX No.',
          sortable: true,
        },
        {
          key: 'paymentType',
          label: 'payment mode',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'cardNumber',
          label: 'Payment Reference',
          sortable: true,
        },
        {
          key: 'cardHolderName',
          label: 'Card Holder name',
          sortable: true,
        },
        {
          key: 'cardHolderContact',
          label: 'card holder contact',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      const separateLines = text.split(/\r?\n|\r|\n/g)
      if (separateLines[index]) {
        return separateLines[index]
      }

      return ''
    },
    getTotal() {
      const total = this.transactions.reduce((n, { amount }) => n + Number(amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
