<template>
  <div>
    <b-table
      v-if="allocations.length"
      ref="refReceiptCNTable"
      class="position-relative event_list shadow-table"
      :items="allocations"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(dnTrxNo)="data">
        <div
          v-if="data.item.dnType.toLowerCase() == 'invoice'"
          class="text-nowrap"
        >
          <b-button
            v-if="canViewThisAction('show', 'Invoice')"
            variant="link"
            class="p-0 weight-600"
            :to="{ name: 'customers-invoices-show', params: { id: data.item.dnTrxID } }"
          >
            {{ data.item.dnTrxNo }}
          </b-button>
          <span
            v-else
            class=""
          >{{ data.item.dnTrxNo }}</span>
        </div>
        <div
          v-else
          class="text-nowrap"
        >
          <span class="">{{ data.item.dnTrxNo }}</span>
        </div>
      </template>
      <template #cell(amount)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.amount) }}</span>
        </div>
      </template>

      <template #bottom-row="">
        <td
          class="bottom-row text-right"
          colspan="4"
          style="padding-right:0 !important"
        >
          <span class="weight-500 fs-18">Total Debit</span>
        </td>
        <td
          class="bottom-row text-left"
        >
          <span class="weight-500 fs-18">{{ getTotal() }}</span>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No allocations found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BButton,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    allocations: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'cnTrxNo',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'cnTrxNo',
          label: 'Receipt/CN Trx No',
          sortable: true,
        },
        {
          key: 'dnTrxNo',
          label: 'Billing/DN Trx No',
          sortable: true,
        },
        {
          key: 'dnType',
          label: 'Billing/DN Type',
          sortable: true,
        },
        {
          key: 'dnItem',
          label: 'Billing/DN Item',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Allocated Amount',
          sortable: true,
        },
      ],
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      if (text) {
        const separateLines = text.split(/\r?\n|\r|\n/g)
        if (separateLines[index]) {
          return separateLines[index]
        }
      }

      return ''
    },
    getTotal() {
      const total = this.allocations.reduce((n, { amount }) => n + Number(amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
